@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.collection-email-container {
  padding: 0rem;

  @media (min-width: $ppui-grid-media-query-md) {
    padding: 4rem 2rem;
  }

  @media (min-width: $ppui-grid-media-query-lg) {
    padding: 8rem 4rem;
  }

  .collection-input-container {

    .collection-email-input {
      margin: 3rem 0;

      @media (min-width: $ppui-grid-media-query-lg) {
        margin: 3rem;
      }
    }

    .collection-input-submit {
      display: block;
      width: 100%;
      margin: 2rem auto;

      @media (min-width: $ppui-grid-media-query-lg) {
        width: 60%;
      }
    }

    .collection-success-alert {
      margin: 2rem auto;
    }
  }

}
