@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.loading-overlay {
  background-color: $ppui-color-white;
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: calc(50vh - 1.5rem);

  .spinner {
    position: absolute;
    left: 50%;
    right: 50%;
  }
}
