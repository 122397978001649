@mixin column-1-span {
  grid-column: 1 / 2;
  justify-self: start;
}

@mixin column-2-span {
  grid-column: 2 / 3;
  justify-self: end;
}

@mixin full-span {
  grid-column: 1 / 3;
}

.marketing-preferences {
  display: grid;
  grid-template-columns: auto 5rem;

  p {
    margin: 0;
  }

  .header-name {
    @include column-1-span;
  }

  .header-checkbox {
    @include column-2-span;
  }

  .entry-name {
    @include column-1-span;
    justify-self: start;
  }

  .entry-checkbox {
    @include column-2-span;
    margin-top: 0;
    align-self: center;

    label {
      display: inline;
    }
  }

  .divider {
    @include full-span;

    &.top {
      margin-bottom: 1.5rem;
    }

    &.bottom {
      margin-top: 1.5rem;
    }
  }

  .save-button {
    @include full-span;
    justify-self: center;
    min-width: 16rem;
  }
}
