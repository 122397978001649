.interest-based-advertising {
  padding-bottom: 3rem;
  text-align: center;

  .content-container {
    width: 100%;
    max-width: 40rem;
    margin: auto;
  }
}
