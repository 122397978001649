@import "~@swift-paypal/pp-tokens/build/web/scss/_tokens.scss";

.header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to right, $ppui-color-blue-500, $ppui-color-blue-700);

  @media (min-width: $ppui-grid-media-query-sm) {
    justify-content: inherit;
  }

  * {
    margin: 1rem 0.5rem;
  }

  :first-child {
    @media (min-width: $ppui-grid-media-query-sm) {
      margin-left: 2rem;
    }
  }

  :last-child {
    @media (min-width: $ppui-grid-media-query-sm) {
      margin-right: 2rem;
    }
  }

  .gap {
    display: none;

    @media (min-width: $ppui-grid-media-query-sm) {
      display: inline;
      flex-grow: 1;
    }
  }

  .logo {
    width: 2rem;
  }

  .logo-link {
    order: 2;
    margin: 0;

    @media (min-width: $ppui-grid-media-query-sm) {
      order: inherit;
    }
  }

  .sign-up {
    order: 1;

    @media (min-width: $ppui-grid-media-query-sm) {
      order: inherit;
    }
  }

  .log-in {
    order: 3;

    @media (min-width: $ppui-grid-media-query-sm) {
      order: inherit;
    }
  }
}
