.email-row {
  margin-bottom: 2rem;

  .email {
    position: relative;

    .email-icon {
      position: relative;
      top: 0.4rem;
      padding-right: 0.5rem;
    }
  }
}

.marketing-preferences-row {
  margin-bottom: 4rem;

  .head {
    margin-top: 0;
  }
}
